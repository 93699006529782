import React from "react";
import logo from "./logo.svg";
import "./App.css";
import IndexPage from "./pages";
import Lost from "./pages/404";
import { BrowserRouter, Switch, Route } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={IndexPage} />
        <Route path="*" component={Lost} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
